import React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/Layout"
import { SEO } from "../components/SEO"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 - Not Found" />
    <div className="container px-6 pb-10 mx-auto sm:px-8 md:px-12 lg:px-6 md:pl-0 md:pr-0">
      <p>
        Page not found. <Link to="/">Return home</Link>
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
